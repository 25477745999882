import React, { useEffect, useState } from 'react'
import Layout from '../components/layout-2'
import { Amplify, API } from 'aws-amplify'
import awsconfic from '../aws-exports'
import { withAuthenticator } from '@aws-amplify/ui-react'
import '@aws-amplify/ui-react/styles.css'
import queryString from 'query-string'
import AccountsTable from '../components/accounts-table'
import LoadingAnimation from '../components/loading-animation'
import {
  finishSelectBankAccount,
  selectBankAccount,
} from '../graphql/mutations'
import SuccessMessage from '../components/success-message'
import { navigate } from 'gatsby'
import FailureMessage from '../components/failure-message'
import SimpleSpinner from '../components/simple-spinner'

Amplify.configure(awsconfic)
const imgStyle = {
  borderRadius: '50%',
  verticalAlign: 'middle',
  marginRight: 20,
  display: 'inline-block',
}

function getContent(
  loading: boolean,
  signOut,
  accountData,
  success,
  selectAccountCB,
) {
  if (!accountData) {
    setTimeout(() => {
      navigate('/own-connections')
    }, 5000)

    return (
      <Layout path="/own-connections" signOut={signOut}>
        <FailureMessage message="Something went frong, redirecting to own connections. Please try again" />
        <div className="container mt-5">
          <SimpleSpinner />
        </div>
      </Layout>
    )
  }

  if (loading) {
    return (
      <Layout path="/own-connections" signOut={signOut}>
        <div className="bg-white">
          <div className="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
            <div className="text-center">
              <p className="mt-1 text-2xl font-extrabold text-gray-900 sm:text-5xl sm:tracking-tight lg:text-6xl">
                Connecting to Bank
              </p>
              <p className="max-w-xl mt-5 mx-auto text-xl text-gray-500">
                When connection is ready you can select account to be connected.
              </p>
            </div>
          </div>
        </div>
        <main>
          <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
            <LoadingAnimation />
          </div>
        </main>
      </Layout>
    )
  } else {
    return (
      <Layout path="/own-connections" signOut={signOut}>
        {success ? (
          <div>
            <SuccessMessage message="Successfully connected to bank, redirecting to connections" />
            <div className="container mt-5">
              <SimpleSpinner />
            </div>
          </div>
        ) : undefined}
        <div className="bg-white">
          <div className="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
            <div className="text-center">
              <p className="mt-1 text-2xl font-extrabold text-gray-900 sm:text-5xl sm:tracking-tight lg:text-6xl">
                <img
                  alt=""
                  style={imgStyle}
                  width="60"
                  height="60"
                  src={accountData.bank?.logo}
                />
                <span>{accountData.bank?.name}</span>
              </p>
              <p className="max-w-xl mt-5 mx-auto text-xl text-gray-500">
                Select account to start syncing bank to YNAB account:{' '}
                {accountData?.ynabAccount?.name}
              </p>
            </div>
          </div>
        </div>
        <main>
          <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
            <AccountsTable
              selectAccountCB={selectAccountCB}
              accounts={accountData}
            />
          </div>
        </main>
      </Layout>
    )
  }
}

// http://localhost:8000/connect-bank?ref=dea19d3f-547b-4f04-853e-663ee9dfe64f&error=Request%20to%20Institution%20returned%20an%20error.%20ReceiveOAuthCode%20error.&details=gAAAAABisZlZ_oJuyTBjtNxApERc_zizPfKflODZsgKK_UKZ-QpAqsTGJBFPiB0j2PmNV4mBpbXx4CJWBighbJo4EOy5Yvdq4-qTNzgILQBLj054qqyICQ9199X_czjCrjxJnk_5Tjj0qPSUs2OpRlmFmP7sDXx7f6pGxDzw0FFI6rekxZSg1JJw1INZnW9dZA60qgIqb8o1FZU0WMFYN2L8M5zKKKjY8y08K4PVnb79IQz6-D-0OKuYsURsERzrB_HoauknRDn3YixQADKqBmJw-1eQU8mggAxqhgr0xfdOv899bp-C0FEgx_lNXkOvVAiPUj9fwwf63Jh-oALnrjQg3VRxJmPgOdXmMMTqL0EiWI6ULPm9_oq6k8HzwIU_v-Ixedelo4QcPhJnYfVqqaKTLyoa1S0jS-HpxYB2UoVBXZ5Be_miBQ_TEVoaLtJ0apWrJedSXBLDPJmgQ61MY3mIbc3EbHXQdrTfvtQiJBVgvqkhAjgEij8aFSsXG8Y4N_Cljx3NwR3DQq8TsyEVDE9cr-Ek45vUTDog2OpB_gQILNL6-3w6EDUU-tJe9lliQ9xVjn6lOe3-oxZF1zBzi3PvsqXfOOqEGpptgZx72kxDuYptp-tpNprSAK3nDEbxoQOx6lvRYJxE1prCnJlm-Im-WcFWK4gJNA==AHbFTjvCSnEbgn/51f16uQ==
const OwnConnections = ({ signOut, user, _serverData, location }) => {
  const [code, setCode] = useState({ ref: undefined })
  const [accountData, setAccountData] = useState([])
  const [loading, setLoading] = useState(true)
  const [success, setSuccess] = useState(false)
  const params = queryString.parse(location.search)
  if (params.ref != code.ref) {
    setCode(params)
  }
  useEffect(() => {
    // fetch all the notes
    void updatePersonInfo(params)
  }, [code])
  const selectAccountCB = async (account, accounts) => {
    const reply = await API.graphql({
      query: finishSelectBankAccount,
      variables: {
        details: JSON.stringify({
          selectedAccount: account,
          accounts,
        }),
      },
    })
    const obj = JSON.parse(reply.data.finishSelectBankAccount)
    setSuccess(true)
    setTimeout(() => {
      navigate('/own-connections')
    }, 3000)
  }

  const updatePersonInfo = async params => {
    const accountData = await API.graphql({
      query: selectBankAccount,
      variables: {
        details: JSON.stringify({
          ...params,
        }),
      },
    })
    const reply = JSON.parse(accountData.data.selectBankAccount)
    setAccountData(reply.body)
    setLoading(false)
    //
  }
  const content = getContent(
    loading,
    signOut,
    accountData,
    success,
    selectAccountCB,
  )

  return <>{content}</>
}

export default withAuthenticator(OwnConnections)
